import React from "react";

interface RenderAudioPlayerProps {
    track: string;
    show: boolean;
}

async function fetch_audio_file(track: string) {
    return await fetch(track).then(()=> {
        return true
    })
}

export const RenderAudioPlayer = ( { show, track }:RenderAudioPlayerProps) => {
    if(!show) return null;
    fetch_audio_file(track).then(r => {
        if(r) {
            return (
                <audio className="w-full" controls controlsList="nodownload" src={track}></audio>
            );
        } else {
            return (
                <div className="w-full bg-red-200 p-8 text-center">
                    Error fetching audio file
                </div>
            );
        }
    }).catch((error) => {
        console.error("Error fetching audio file: ", error);
    })

    return (
        <div className="w-full bg-red-200 p-8 text-center">
            Error fetching audio file
        </div>
    );
}