import React from "react";
import { getTrack } from "../resources/playlist";
import {useRecoilValue} from "recoil";
import {ShowPlayerState} from "../store/ShowPlayerStore";
import {RenderAudioPlayer} from "./RenderAudioPlayer";
import {RenderContentGate} from "./RenderContentGate";

interface MainContentProps {
    isOnline: boolean;
}

function Content(isOnline: boolean) {
    const showPlayer = useRecoilValue(ShowPlayerState);
    const driftToPeace = getTrack(0);
    const driftToPeaceSrc = driftToPeace[0].src;

    if(!isOnline) {
        return (
            <div>
                <h1 className="mb-2 text-center font-bold">You Are Offline</h1>
                <p className="mb-10 text-center">No internet connection. To access this app, connect to the internet.</p>
            </div>
        );
    }

    return(
        <div>
            <h1 className="mb-2 text-center font-bold">Liminal Space</h1>
            <p className="mb-10 text-center">Lorem ipsum odor amet, consectetuer adipiscing elit. Eros neque efficitur maximus pulvinar morbi non. Dapibus scelerisque dui duis natoque ligula imperdiet nec faucibus. Nisl eros habitant porta nisi gravida purus fringilla libero. Fermentum montes habitasse penatibus phasellus fermentum lacus quam.</p>
            <h2 className="mb-0 text-center text-2xl font-bold">{ driftToPeace[0].name }</h2>
            <p className="mb-4 text-center">{ driftToPeace[0].description }</p>
            <RenderContentGate show={showPlayer} />
            <RenderAudioPlayer track={driftToPeaceSrc} show={showPlayer} />
        </div>
    );
}

export const MainContent = ( { isOnline }:MainContentProps) => {
    return (
        <article className="container flex h-screen items-center justify-center">
            <div>
                {Content(isOnline)}
            </div>
        </article>
    );
}